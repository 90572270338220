ul {
  list-style: none;
  /*margin-right: 5%; !*200px;*!*/
  /*margin-left: 5%; !*200px;*!*/
  padding: 0;
}

ul li{
  clear: both;
  margin-right: 15%; /*200px;*/
  margin-left: 15%; /*200px;*/
  margin-top: 10px;
  margin-bottom: 10px;
}


.left-review {
  float: left;
}

.right-review {
  float: right;
}


.review-container {
  position: relative;
  min-height: 112px;
  max-width: 500px;

  font-family:Georgia;
  font-style:italic;
}

.review-container .message {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 40px 120px;
  text-align: left;
  color:#ffffff;
  line-height: 1.2;
  font-size: 18px;
}

.review-container .icon {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 112px;
  height: 112px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 15px -3px;
  background-color: #ccb481;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.review-container .footer {
  background: rgb(207, 180, 134);
  border-radius: 0 0 50% 50%;
  border-bottom-left-radius: 150px 100px;
  border-bottom-right-radius: 150px 100px;
  height: 37px;
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 0;
  padding-left: 112px;
  display: flex;
  flex-direction: row;
  box-shadow: -1px 2px 9px -3px rgba(0, 0, 0, .5);
  color: #3d2d12;
}

.review-container .footer .col-large p {
  margin-top: 3px;
  margin-bottom: 0px;
  line-height: 13px;
  text-align: left;
  font-size: 13px;
}

.review-container .footer .symbol {
  font-size: 95px;
  line-height: 37px;
  position: relative;
  top: 26px;
  margin-left: 20px;
}

@media screen and (max-width: 520px) {
  .review-container .footer .symbol {
    margin-left: 3px;
  }
  .review-container .message {
    font-size: 15px;
  }
}

@media screen and (max-width: 1040px) {
  ul li {
    margin-right: 10px; /*200px;*/
    margin-left: 10px; /*200px;*/
  }
}
