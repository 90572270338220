@media screen and (max-width: 640px) {
  .biography-gallery {
    display: block;
    width: calc(100% - 20px);
  }
  .reviews-gallery {
    width: 80%;
  }
}

/* header overrides main section on old iPhone devices, adding a bit margin */
@media screen and (max-width: 400px) {
  .app-content {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .home-banner {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 840px) {
  .home-banner {
    position: relative;
  }
  .home-banner > .banner-container{
    flex-direction: column;
  }
  .home-banner > .banner-container .event-item-calendar-date {
    max-width: 120px;
    width: 75px;
  }
}