/* https://blog.hellojs.org/flexbox-sticky-footer-and-react-d116e4cfca5
 * https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  color: white;
}
.app-content {
  text-align: center;
  flex: 1 0 auto;
  padding: var( — space) var( — space) 0;
  width: 100%;
}
.app-content:after {
  content: ‘\00a0’;
  display: block;
  margin-top: var( — space);
  height: 0;
  visibility: hidden;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 1em 0em 1em;
    padding: 0;
}

footer p {
    color: #ffffff;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.bg-dark-overlay {
  background: rgba(0,0,0,1); /* rgba(0,0,0,0.2); */
}

/*.concert-list {*/
  /*align-items: center;*/
/*}*/

:root {
  --color-main: #cfb486; /* rgb(207,180,134) */
  --color-main-dot-five: rgba(207, 180, 134, 0.5);
  --color-main-dot-eight: rgba(207, 180, 134, 0.8);
}

.biography-gallery {
  margin: 0px 10px 10px;
  width: 495px;
  height: auto;
  transition: all 1s ease;
  display: inline-block;
  float: right;
}

.concert-item {
  width: 70%;
  color: white;
  /*border: 5px solid #ffcc5c;*/
  border-top: 1px solid var(--color-main-dot-five);
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.concert-item-flag {
  width: calc(100% / 8 * 1);
  align-self: center;
}

.concert-item-body {
  width: calc(100% / 4 * 7);
  flex-direction: column;
  align-self: flex-start;
}

.concert-item-body > p {
  text-align: left;
  font-family: "Open Sans", sans-serif;
}

.concert-item-header {
  font-size: 16px;
  margin-bottom: 3px;
  /*margin-top: 10px;*/
}

.concert-item-date, .concert-item-description {
  font-size: 13px;
  margin-bottom: 0;
}

.concert-item-description {
  margin-top: 3px;
  line-height: normal;
}

.app-image-gallery {
  margin: 0 auto;
  width: 80%;
  height: auto;
  transition: all 1s ease;
}

.recordings-card .youtube-container {
  height: 230px;
  background: black;
}

.image-gallery-slide img {
  /* width: 100%; */
  width: auto;
  max-height: 100vh;
  background: black;
}

body {
  /* see:
      - https://react-bootstrap.netlify.com/components/navbar/#navbars-placement
      - https://stackoverflow.com/a/11124805/1233652
   */
  padding-top: 56px;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: var(--color-main-dot-eight) !important;
}

.nav-link.active {
  color: var(--color-main) !important;
}

/* grid-template-rows: repeat(2, 100%); */
.recordings {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-left: 10%;
  margin-right: 10%;
}

.home-banner {
  position: absolute;
  max-width: calc(100% - 16px);
  width: 770px;
  background-color: rgba(57,53,53,0.5);
  border-color: rgb(7,7,6);
  border-width: 1px;
  border-style: solid;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 8px auto;
  margin-bottom: 175px;
  padding: 0;
  padding-bottom: 16px;
}

.reviews-gallery {
  margin: 0 auto;
  width: 60%;
  height: auto;
  transition: all 1s ease;
}

.banner-container {
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", sans-serif;
}

.banner-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}


.event-item {
  /*width: 90%;*/
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  /*margin-left: 5%;*/
  /*margin-right: 5%;*/
  padding-top: 10px;
  padding-bottom: 10px;
}

.event-item-calendar-date {
  width: 75px;
  align-self: center;
  border: 0;
  background-color: rgba(207, 180, 134, 1);
  border-radius: 0;
  color: #3d2d12;
  font-weight: bold;
  flex: 1;
  letter-spacing: 5px;
}

.event-item-body {
  width: calc(100% / 4 * 7);
  flex-direction: column;
  flex: 3;
}

.event-item-text {
  margin-bottom: 0;
  font-size: 13px;
  text-align: left;
  padding-left: 15px;
}

.banner-column-header {
  color: rgb(207, 180, 134);
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 6px;
  text-align: left;
  margin-top: 15px;
}

.banner-more-events {
  color: rgb(207, 180, 134);
  margin-left: auto;
  margin-right: 20px;
  text-align: right;
  margin-top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.banner-underline {
  box-sizing: border-box;
  border-top-color: #e7d3b1;
  border-top-style: solid;
  border-top-width: 1px;
}

.horizontal-image {
  grid-row: span 2;
  grid-column: span 3;
}

.vertical-image {
  grid-row: span 3;
  grid-column: span 2;
}

.large-square-image {
  grid-row: span 3;
  grid-column: span 3;
}